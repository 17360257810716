.videoExCard{
    
    // border-bottom:1px solid var(--black-01);
    .imgArea{
        width: 100%;
        padding-bottom:55%;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        svg{
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
        }
        img{
            position:absolute;
            top:0;
            left:50%;
            transform: translateX(-50%);
            width:100%;
            // height:auto !important;
            object-fit: cover;
        }
    }  
    .descArea{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 128.571%;
        letter-spacing: -0.3px;
        word-break:normal;
        margin:12px 0;
    } 
    .timeArea{
        font-size: 0.813rem;
        font-weight: 300;
        line-height: 123.077%;
        letter-spacing: -0.3px;
        color: var(--black-08);
        span{
            color: var(--black);
            margin-left:6px;
        }
    }
}