.nav {
  height: 72px;
  width: 100%;
  max-width: calc(430px);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  border: 0.5px solid var(--black-005, rgba(0, 0, 0, 0.05));
  background: var(--white-08, rgba(255, 255, 255, 0.8));
  backdrop-filter: blur(8px);
}

.navList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 6px 1.2em 14px;
  .li {
    width: 2.6em;
    min-width: 64px;
    a {
      width: 100%;
      display: block;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2px;
      height: 24px;
      svg {
        width: 24px;
        object-fit: cover;
        fill: rgba(0, 0, 0, 0.4);
      }
    }
    .value {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.4);
      font-weight: 400;
      line-height: 145.455%;
      letter-spacing: -0.3px;
    }
    &.on {
      svg {
        fill: var(--black);
      }
      .value {
        color: var(--black);
      }
    }
  }
}

.joinNavigator {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--white-2, #f8f8f8);
  padding: 0.5em 1.5em 1.5em;
  display: flex;
  gap: 0.75em;
  width: calc(100% - 3em);
  max-width: calc(430px - 3em);
  z-index: 20;
  button {
    flex: 1;
    display: block;
    height: 48px;
    font-size: 1em;
    &:first-child {
      border: 1px solid var(--primary-mobile, #66b6ff);
      background: var(--white-2, #f8f8f8);
      color: var(--primary-mobile, #66b6ff);
    }
  }
}
