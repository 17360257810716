.home {
  .section1 {
    background: #f8f8f8 url("") top center/100% no-repeat;
    article {
      padding: 0 1.5em 1.5em;
    }
    h1 {
      // padding-top: 25vw
      padding-top: 120px;
      svg {
        fill: var(--white);
      }
    }
    h2 {
      min-height: 160px;
      p {
        margin-top: 2px;
      }
      & > .item1 {
        width: 30%;
        min-height: 20px;
      }
      & > .item2 {
        width: 50%;
        min-height: 20px;
      }
      & > pre {
        font-size: 1rem;
        line-height: 125%;
        color: var(--white);
        font-weight: 400;
        margin-top: 8px;
        // padding-bottom: 32%;
        padding-bottom: 88px;
      }
    }
  }
  .section4 {
    padding-bottom: 6em !important;
  }
}

.isdata {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  background: var(--black-005);
  section {
    background: var(--white-2);
  }
  .section1 {
    article {
      padding: 0 1.5em;
    }
    .dataArea {
      padding: 0 1em;
    }
    .resultList {
      margin-top: 1.12em;
      min-height: 368px;
      background: var(--white-2);
      border-radius: 1em 1em 0px 0px;
      padding: 0.4em 1em;
    }
  }

  .section2 {
    padding-bottom: 1.4em;
    width: 100%;
    overflow: hidden;
    .sctionTitle {
      padding: 1.5em 1.5em 1em;
    }
  }
  .section3 {
    padding: 1.5em;
    .resultList {
      margin: 1em 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .gradeArea {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
  }
  .section4 {
    padding: 1.5em;
  }
  .moreBtn {
    display: block;
    button {
      display: block;
      width: 100%;
      font-size: 0.85em;
      padding: 0.7em 0.7em;
      border-radius: 0.4em;
    }
  }
}

.nodata {
  .sectionTitle {
    font-weight: 700;
    font-size: 1.3em;
    line-height: 120%;
    margin-bottom: 0.4em;
    min-height: 24px;
    word-break: keep-all;
  }
  .sectionDesce {
    font-size: 0.9em;
    font-weight: 300;
    line-height: 128.571%;
    color: var(--black-08);
    min-height: 18px;
  }
  .item {
    border-radius: 8px;
    height: 100%;
    position: relative;
    overflow: hidden;
    .imgTitle {
      color: var(--white, #fff);
      font-size: 1.125em;
      font-weight: 700;
      line-height: 122.222%;
      position: absolute;
      top: 50%;
      left: 2em;
      transform: translateY(-50%);
    }
  }
  .infoArea,
  .section2,
  .section3 {
    padding: 0 1em;
  }
  .section2,
  .section3,
  .section4 {
    background-color: var(--white-2);
  }
  .section1 {
    .imageArea {
      min-height: 200px;
      max-height: calc(430px * 0.6);
      display: grid;
      grid-template-columns: 1fr 42%;
      gap: 0.5em;
      padding: 0.875em 0 2.25em;
      .item:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
      }
    }
  }
  .section2 {
    .imageArea {
      height: 180px;
      // max-height:calc(430px * 0.419);
      padding: 1em 0 2.5em;
    }
  }
  .section3 {
    .equipmentArea {
      padding: 1.5em 0 2.5em;
      & > div {
        margin-bottom: 0.4em;
      }
    }
  }
  .section4 {
    .sectionTitle {
      padding: 0 1.2em;
      & > pre {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 120%;
        margin-bottom: 0.4em;
        min-height: 24px;
        word-break: keep-all;
        text-align: center;
        white-space: normal;
      }
    }
    .sectionDesce {
      padding: 0 1.72em;
      text-align: center;
    }
    .imageArea {
      padding-top: 1em;
      .item {
        height: 40vw;
        max-height: calc(430px * 0.4);
        border-radius: 0;
        margin-bottom: 0.375em;
      }
    }
  }
}
