.select{
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    display: flex;
    align-items: center;
    position: relative;
    svg{
        width:16px;
        height:16px;
        fill : var(--black);
        position: absolute;
        right:8px;
        margin-top:2px;
    }
    select{
        background-color:rgba(0,0,0,0);
        font-size:14px;
        background: transparent;
        -webkit-appearance: none;
        width: 100%;
        border: 0;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        // text-align: right;
        padding:0 30px 0 16px;
        border:none;
        outline: none;
    }
}