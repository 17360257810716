.defaultBtn{
    font-size:0.813em;
    padding:0.67em;
    background: var(--primary-mobile, #66B6FF);
    border-radius: 0.2em;
    color: var(--white, #FFF);
    opacity: 1;
    transition: all 0.2s;
    border:1px solid var(--primary-mobile, #66B6FF);
    cursor: pointer;
    &:active, &:hover{
        opacity: 0.8;
    }
}