.equipmentInfo {
  border-radius: 0.5em;
  background: var(--white);
  padding: 0.8em 1em;
  .topArea {
    display: flex;
    gap: 1em;
    align-items: center;
    .icon {
      min-width: 28px;
      width: 28px;
      height: 28px;
      position: relative;
      // background: var(--black-04);
    }
    .openIcon{
        min-width: 20px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .title {
        flex:1;
      h4 {
        font-size: 1em;
        font-weight: 500;
        line-height:  125%;
        margin-bottom: 4px;
      }
      p {
        color: var(--black-06);
        font-size: 0.8em;
        font-weight: 300;
        line-height: 133.333%;
      }
    }
  }
  .bottomArea{
    transition: all 0.3s;
    display: flex;
    gap: 1em;
    .img{
        width: 109px;
        min-width: 109px;
        height: 106px;
        position: relative;
    }
    .text{
        color: var(--black-08);
        font-size: 0.875em;
        font-weight: 400;
        line-height: 128.571%;
    }
  }
}
