.changePWModal{
    .item{
        padding-bottom:16px;
        border-bottom:1px solid var(--black-01);
        margin-bottom:16px;
        &:last-child{
            border:none;
            margin-bottom:32px;
        }
    }
}