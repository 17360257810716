.login {
  padding: 84px 1.5em 0;
  background: var(--white-2, #f8f8f8);
  margin-bottom: 0.75em;
  h1 {
    svg {
      fill: var(--black);
    }
  }
  section {
    h2 {
      color: var(--primary-mobile, #66b6ff);
      font-size: 1.25em;
      font-weight: 500;
      line-height: 120%;
      margin: 1em 0;
    }
    .emailArea {
      margin-bottom: 12px;
    }
    .pwArea {
      margin-bottom: 32px;
    }

    .loginBtn {
      button {
        width: 100%;
        font-size: 1em;
        padding: 0.75em;
      }
    }
  }
  .checkBox {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    p {
      font-size: 14px;
    }
  }

  .useService {
    border-radius: 8px;
    border: 1px solid var(--primary-mobile, #66b6ff);
    background: var(--white-005, rgba(255, 255, 255, 0.05));
    padding: 1em;
    margin: 2em 0;
    h3 {
      color: var(--black, #000);
      font-size: 0.813em;
      font-weight: 500;
      line-height: 123.077%;
    }
    .desc {
      color: var(--black, #000);
      font-size: 0.75em;
      font-weight: 300;
      line-height: 133.333%;
      margin: 0.75em 0 1em;
    }
    .useBtn {
      p {
        justify-content: flex-start;
        span {
          color: var(--primary-mobile, #66b6ff);
          font-size: 0.813em;
          font-weight: 500;
        }
        svg {
          fill: var(--primary-mobile, #66b6ff);
        }
      }
    }
  }
  .findArea {
    h3 {
      color: var(--black-06, rgba(0, 0, 0, 0.6));
      font-size: 0.813em;
      font-weight: 400;
      line-height: 123.077%;
    }
    .findBtns {
      display: flex;
      gap: 16px;
      padding: 1em 0 2em;
    }
  }
}
