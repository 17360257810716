.terms{
    color: var(--black-08, rgba(0, 0, 0, 0.80));
    font-size: 13px;
    font-weight: 300;
    line-height:123.077%;
    h1, h2, h3 {
        color: var(--black-08, rgba(0, 0, 0, 0.80));
        font-size: 13px;
        font-weight: 300;
        line-height:123.077%;
    }
    table{
        border-collapse: collapse;
        max-width:100%;
    }
    td{
        background-color: var(--white);
    }
    th{
        background-color: #e5f1fc;
    }
    td, th{
        border: 1px solid var(--black-01, rgba(0, 0, 0, 0.10));
        padding:8px;
        color: var(--black-08, rgba(0, 0, 0, 0.80));
        font-size: 13px;
        font-weight: 300;
        line-height:123.077%;
        width:33%;
        word-break:break-all
    }
}