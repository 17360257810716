:root {
  --black: #000;
  --white: #fff;
  --mode: #fff;
  --mode-main-navy-10: #e5e6f1;
  --mode-main-navy-20: #9397c6;
  --mode-main-navy-30: #4c52a0;
  --mode-main-navy-40: #272e87;
  --mode-main-navy-70: #00043c;
  --mode-main-navy-60: #00054b;
  --mode-main-navy-50: #040a5c;
  --mode-main-navy-80: #00032c;
  --mode-main-blue-10: #e6f3ff;
  --mode-main-blue-20: #c3e0ff;
  --mode-main-blue-40: #7bb9ff;
  --mode-main-blue-50: #65aaff;
  --mode-main-blue-30: #9ecdff;
  --mode-main-blue-60: #589cfd;
  --mode-main-blue-70: #548dee;
  --mode-main-blue-80: #4f7bda;
  --mode-main-pink-20: #ffaed8;
  --mode-main-pink-30: #ff87c5;
  --mode-main-pink-10: #ffc7e4;
  --mode-main-pink-40: #ff59ae;
  --mode-main-pink-50: #ff319a;
  --mode-main-pink-60: #dd0775;
  --mode-main-pink-70: #c70066;
  --mode-sub-cobalt-blue: #264a9f;
  --mode-main-pink-80: #a90056;
  --mode-sub-lavender-blue: #b9d6ff;
  --mode-sub-picton-blue: #4ab4de;
  --mode-sub-green: #00c88c;
  --mode-sub-coral: #ff3737;
  --mode-sub-bisque: #ffe4c4;
  --mode-grey-10: #f2f2f2;
  --mode-grey-20: #eaeaea;
  --mode-grey-30: #d4d4d4;
  --mode-grey-40: #aaa;
  --mode-grey-50: #7d7d7d;
  --mode-grey-60: #626262;
  --mode-grey-70: #505050;
  --mode-grey-80: #303030;
  --mode-opacity-20: rgba(0, 0, 0, 0.08);
  --mode-opacity-10: rgba(0, 0, 0, 0.06);
  --mode-opacity-40: rgba(0, 0, 0, 0.12);
  --mode-opacity-30: rgba(0, 0, 0, 0.1);
  --mode-opacity-50: rgba(0, 0, 0, 0.14);
  --mode-opacity-60: rgba(0, 0, 0, 0.16);
  --mode-opacity-70: rgba(0, 0, 0, 0.18);
  --mode-opacity-80: rgba(0, 0, 0, 0.2);
  --mode-sub-orange: #ff8d23;
  --primary-purple: #b10fda;
  --primary-navy: #01031b;
  --primary-blue: #34c5ff;

  
  --black-005: rgba(0, 0, 0, 0.05);
  --black-01: rgba(0, 0, 0, 0.1); 
  --black-02 : rgba(0, 0, 0, 0.20);
  --black-04: rgba(0, 0, 0, 0.4);
  --black-06: rgba(0, 0, 0, 0.6);
  --black-07: rgba(0, 0, 0, 0.7);
  --black-08: rgba(0, 0, 0, 0.8);
  --black-2: #0c0c0c;
  
  --white-2: #f8f8f8;
  --graph-green: #1eda00;
  --graph-yellow: #faff00;
  --graph-orange: #f90;
  --graph-red: #ff2128;
  --primary-mobile : #66B6FF;
  --mode-sub-coral : #FF3737;
  --graph-violet : #9E048E;

  --white-01 : rgba(255, 255, 255, 0.10);
  --white-02 : rgba(255, 255, 255, 0.20);
  --white-06 : rgba(255, 255, 255, 0.60);
  --white-08 : rgba(255, 255, 255, 0.80);
}

[data-theme="dark"] {
  --black: #000;
  --white: #fff;
  --mode: #141414;
  --mode-main-navy-60: #363c90;
  --mode-main-navy-50: #121a8a;
  --mode-main-navy-40: #0e156e;
  --mode-main-navy-20: #070a37;
  --mode-main-navy-80: #787ba6;
  --mode-main-navy-70: #454a88;
  --mode-main-blue-10: #304762;
  --mode-main-blue-20: #3e5f8f;
  --mode-main-navy-30: #0b1053;
  --mode-main-blue-30: #3e69ae;
  --mode-main-blue-40: #3f79cc;
  --mode-main-blue-50: #569cf2;
  --mode-main-navy-10: #04051c;
  --mode-main-blue-60: #65aaf5;
  --mode-main-blue-80: #9dcefa;
  --mode-main-pink-10: #33011b;
  --mode-main-blue-70: #7bbaf7;
  --mode-main-pink-30: #990350;
  --mode-main-pink-20: #660235;
  --mode-main-pink-40: #cc046b;
  --mode-main-pink-60: #ff3fa1;
  --mode-main-pink-80: #ff9ace;
  --mode-main-pink-50: #ff0585;
  --mode-main-pink-70: #ff73bb;
  --mode-sub-cobalt-blue: #2558cf;
  --mode-sub-lavender-blue: #8dbaf8;
  --mode-sub-coral: #ed3137;
  --mode-sub-picton-blue: #34c5ff;
  --mode-sub-green: #07b07e;
  --mode-sub-bisque: #ffd29c;
  --mode-grey-10: #272727;
  --mode-grey-20: #323232;
  --mode-grey-30: #505050;
  --mode-grey-50: #8a8a8a;
  --mode-grey-40: #636363;
  --mode-grey-60: #ababab;
  --mode-grey-70: #d0d0d0;
  --mode-grey-80: #e2e2e2;
  --mode-opacity-20: rgba(255, 255, 255, 0.08);
  --mode-opacity-10: rgba(255, 255, 255, 0.06);
  --mode-opacity-30: rgba(255, 255, 255, 0.1);
  --mode-opacity-40: rgba(255, 255, 255, 0.12);
  --mode-opacity-50: rgba(255, 255, 255, 0.14);
  --mode-opacity-60: rgba(255, 255, 255, 0.16);
  --mode-opacity-70: rgba(255, 255, 255, 0.18);
  --mode-opacity-80: rgba(255, 255, 255, 0.2);
  --mode-sub-orange: #ffa723;
}
