.step{
    background: var(--white-2, #F8F8F8);
    padding:100px 1.5em 30px;
    margin-bottom:0.75em;
    h1{
        color: var(--primary-mobile, #66B6FF);
        font-size: 1.875em;
        font-weight: 700;
        line-height:120%;
    }
    .barArea{
        display:flex;
        height:0.25em;
        width:100%;
        gap:6px;
        margin:2em 0 1em;
        .bar{
            height:100%;
            width: 48px;
            border-radius: 2px;
            background: var(--black-01, rgba(0, 0, 0, 0.10));
            &.on{
                background-color:var(--primary-mobile, #66B6FF);
            }
        }
    }
    .stpe{
        line-height: 125%;
        font-weight: 500;
        color: var(--black, #000);
        span{
            color: var(--primary-mobile, #66B6FF);
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1rem;
        }
    }
    .text{
        color: var(--black-08, rgba(0, 0, 0, 0.80));
        font-size: 0.813em;
        font-style: normal;
        font-weight: 300;
        line-height:123.077%;
        padding:1em 0 0.75em;
    }
}