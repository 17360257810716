.onoffBtn{
    border-radius: 4px;
    // border: 1px solid var(--black-01, rgba(0, 0, 0, 0.10));
    // background: var(--black-01, rgba(0, 0, 0, 0.10));
    overflow: hidden;
    width: 112px;
    display:flex;
    align-items: center;
    button{
        flex:1;
        color: var(--black-06);
        font-size: 12px;
        font-style: normal;
        line-height: 133.333%;
        padding:0.25em;
        transition: all 0.2s;
        font-weight: 400;

    }
    .offbtn{
        border: 1px solid var(--black-01, rgba(0, 0, 0, 0.10));
        background: var(--black-02, rgba(0, 0, 0, 0.20));
    }
    .onbtn{
        border: 1px solid var(--black-01, rgba(0, 0, 0, 0.10));
        background: var(--black-01, rgba(0, 0, 0, 0.10));
    }
    &.on{
        .offbtn{
            border: 1px solid var(--black-01, rgba(0, 0, 0, 0.10));
            background: var(--black-01, rgba(0, 0, 0, 0.10));
        }
        .onbtn{
            border: 1px solid var(--black-01, rgba(0, 0, 0, 0.10));
            background: var(--primary-mobile, #66B6FF);
            color: #000;
        }
    }
   
}