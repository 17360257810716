@import "../../inputs/textInput/textInput.module.scss";

.changeGenderModal {
  
  .itemTitle {
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    font-size: 0.875em;
    line-height: 128.571%;
    margin-bottom:8px;
    &.failtext{
        color:var(--light-sub-coral, #FF3737);
    }
  }
  .nowGender {
    color: var(--black, #000);
    font-size: 1em;
    font-weight: 500;
    line-height: 125%;
    padding:8px 0 16px;
    margin-bottom:16px;
    border-bottom: 1px solid var(--black-01);
  }


  .select{
    height: 42px;
    margin-top: -2px;
    & > div {
      border: 1px solid var(--black-01, rgba(0, 0, 0, 0.1));
      border-radius: 4px;
    }
  }
  select {
    width:100%;
    height:100%;
    &:focus {
      outline-color: var(--primary-mobile, #66b6ff);
    }
  }

  .emailText{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    font-size: 0.75em;
    line-height: 133.333%;
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    &.success{
      color: var(--light-sub-green, #00C88C);
      svg{
        fill : var(--light-sub-green, #00C88C);
        width:16px;
        height:16px;
      }
    }
    &.fail{
      color: var(--light-sub-coral, #FF3737);
      svg{
        fill : var(--light-sub-coral, #FF3737);
        width:16px;
        height:16px;
      }
    }
  }

  .checkBtn{
    margin-top:8px;
    button{
        width:100%;
        background: var(  --black-04, rgba(0, 0, 0, 0.4));
        border:none;
    }
  }
}

