.modalLayout{
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    padding:1.375em;
    width:calc(100% - 4.75em);
    max-width:calc(430px - 4.75em);
    border-radius: 1.5em;
    background: var(--white-2, #F8F8F8);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
    .top{
        display:flex;
    }
    .children{
        padding: 0 0.625em;
        margin:24px 0 21px;
    }
    .title{
        flex:1;
        text-align: center;
        text-indent: 24px;
        font-size: 1.125em;
        font-weight: 700;
        line-height: 122.222%;
    }
    .closeBtn{
        background-color:none;
        min-width: 24px; 
        width: 24px;
        height: 24px;
        margin-left:auto;
    }
    .btns{
        display:flex;
        align-items: center;
        i{
            display:block;
            background: var(--black-02);
            width: 1px;
            height: 32px;
        }
        button{
            flex:1;
            font-size: 1.125em;
            font-weight: 500;
            line-height: 122.222%;
            &.left{
                color: var(--black-04); 
            }
            &.right{
                color: var(--black, #000);
            }
        }
    }
}

.dimmed{
    background: var(--black-07);
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:100%;
    height:100vh;
    z-index: 100;
}