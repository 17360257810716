.my {
  background: var(--white-2);
  padding-top: calc(101px);
  // padding-bottom:10em;
  margin-bottom: 0.75em;
  .myInfo {
    background: var(--black-005);
  }
  section {
    background: var(--white-2);
    margin-bottom: 0.75em;
    padding: 1.5em;

    .message {
      color: var(--black-06);
      font-size: 0.75em;
      font-weight: 300;
      line-height: 133.333%;
    }
    .item {
      border-top: 1px solid var(--black-01);
      font-size: 0.875em;
      font-weight: 300;
      line-height: 128.571%;
      padding: 0.75rem 0;

      & .onlyBtnItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.25em;

        .key {
          margin-bottom: 0;
        }
      }

      .key {
        min-width: 100px;
        color: var(--black-06);
        word-break: keep-all;
        margin-bottom: 0.75rem;
      }
      .value {
        flex: 1;
        word-break: break-all;
        font-size: 1.143em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 46px;
          border-radius: 4px;
          border: 1px solid var(--black-01);
          background: var(--black-01);
          color: var(--black-06);
          height: 1.5em;
          min-height: 24px;
          font-size: 0.9em;
        }
      }
      &.isChange > b {
        max-width: calc(100% - 170px);
      }
      &.secession button {
        background: var(--mode-sub-coral);
        color: var(--black-08);
      }
      &:first-child {
        border: none;
      }
      &.onlyBtnItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .value {
          justify-content: flex-end;
        }
        .key {
          margin-bottom: 0;
        }
      }
    }
    & > .logout {
      margin-top: 1em;
      button {
        color: var(--black-06);
        font-size: 14px;
        line-height: 128.571%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.6em;
        margin-left: auto;
      }
    }
  }
  .select {
    flex: 1;
    height: 42px;
    select {
      font-size: 0.875rem;
    }
  }
}
