.footer{
    background-color: var(--white-2);
    padding:1.5em 1.5em 7em;
    .number{
        font-size: 1em;
        font-weight: 500;
        line-height: 125%;
        margin-bottom:0.75em;
    }
    .time{
        font-size: 0.813em;
        font-weight: 300;
        line-height: 123.077%;
        margin-bottom:1.5em;
        color: var(--black-08, rgba(0, 0, 0, 0.80));
    }
    .copyright{
        font-size: 0.75em;
        font-weight: 300;
        line-height: 133.333%;
        color: var(--black-06, rgba(0, 0, 0, 0.60));
    }
}