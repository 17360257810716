.checkInput{
    display:flex;
    gap:8px;
    align-items: center;
    .checkInputBox{
        display:flex;
        align-items: center;
        input[type="checkbox"] {
          opacity: 0;
          width: 1px;
          height: 1px;
        }
        input[type="checkbox"] + span {
          background-color: rgba(0, 0, 0, 0);
          border: 1px solid var(--black-02);
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;
          vertical-align: middle;
          margin-bottom: -4px;
          border-radius:0.2em;
          cursor: pointer;
          display:flex;
          justify-content: center;
          align-items: center;
        }
        svg{
          fill:var(--primary-mobile, #66B6FF) !important;
          width: 14px;
          height:14px;
        }
      
        input[type="checkbox"] + span svg {
          display: none;
        }
      
        input[type="checkbox"]:checked + span {
          svg {
            display: block;
          }
        }

      
        input[type="checkbox"]::after {
          display: none;
        }
      }
      
}
