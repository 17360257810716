.state{
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 123.077%;
    width: 4em;
    min-width: 4em;
    padding:0.18em;
    border-radius: 0.95em;
    color: var(--white);
    display: block;
    text-align: center;
}

.text{
    color: var(--black);
    font-size: 1em;
    font-weight: 500;
    line-height: 125%;
}

.gradeCard{
    border-radius: 8px;
    background: var(--black-005);
    padding:1em 0;
    p{
        text-align: center;
    }
    .state{
        margin:0.8em auto 0;
        min-width: 64px;
        padding:0 5px;
        min-height:24px;
        line-height:24px;
        background: var(--dark-grey-50, #8A8A8A);
        font-size:0.8em;
    }
   
}

.gradeCard2{
    border-radius: 8px;
    background: var(--black-005);
    display: flex;
    align-items: center;
    gap:8px;
    padding:0.8em 1em;
    .result{
        display: flex;
        align-items: center;
        gap:9px;
        p{
            display: flex;
            align-items: center;
            gap:9px;
            font-size: 0.8em;
            color: var(--black-06)
        }
    }
    .text{
        flex:1;
    }
    .state{
        min-width: 64px;
        padding:0 5px;
        min-height:24px;
        line-height:24px;
        background: var(--dark-grey-50, #8A8A8A);
        font-size:0.95em;
    }
}

