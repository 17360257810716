@import "../../inputs/textInput/textInput.module.scss";

.changeEmailModal {
  .itemTitle {
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    font-size: 0.875em;
    line-height: 128.571%;
    margin-bottom: 8px;
    &.failtext {
      color: var(--light-sub-coral, #ff3737);
    }
  }
  .nowEmail {
    color: var(--black, #000);
    font-size: 1em;
    font-weight: 500;
    line-height: 125%;
    padding: 8px 0 16px;
    border-bottom: 1px solid var(--black-01);
    margin-bottom: 16px;
  }
  .section {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &.match {
      border-top: 1px solid var(--black-01);
      animation: visible 0.5s;
    }
  }
  .title {
    font-size: 14px;
    letter-spacing: -0.3px;
  }
  .countTitle {
    display: flex;
    justify-content: space-between;
    .count {
      color: var(--primary-mobile);
    }
  }
  .selectEmail {
    display: flex;
    gap: 8px;
    margin: 8px 0;
    .emailIcon {
      display: flex;
      align-items: center;
      font-size: 0.9em;
    }
    .select {
      height: 38px;
      width: 170px;
    }
  }
  .emailText {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    font-size: 0.75em;
    line-height: 133.333%;
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    &.success {
      color: var(--light-sub-green, #00c88c);
      svg {
        fill: var(--light-sub-green, #00c88c);
        width: 16px;
        height: 16px;
      }
    }
    &.fail {
      color: var(--light-sub-coral, #ff3737);
      svg {
        fill: var(--light-sub-coral, #ff3737);
        width: 16px;
        height: 16px;
      }
    }
  }

  .checkBtn {
    margin-top: 8px;
    button {
      width: 100%;
      background: var(--black-04, rgba(0, 0, 0, 0.4));
      border: none;
    }
  }
}
