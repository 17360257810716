@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Sms {
  padding: 0 1rem 80px;
  .section {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &.match {
      border-top: 1px solid var(--black-01);
      animation: visible 0.5s;
    }
  }
  .title {
    font-size: 14px;
    letter-spacing: -0.3px;
  }
  .countTitle {
    display: flex;
    justify-content: space-between;
    .count {
      color: var(--primary-mobile);
    }
  }
  .select {
    width: calc(100% - 2px);
    height: 38px;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid var(--black-01, rgba(0, 0, 0, 0.1));
    border-radius: 4px;
    background: var(--black-005);
    svg {
      width: 16px;
      height: 16px;
      fill: var(--black);
      position: absolute;
      right: 8px;
      margin-top: 2px;
    }
    select {
      background-color: rgba(0, 0, 0, 0);
      font-size: 14px;
      background: transparent;
      -webkit-appearance: none;
      width: 100%;
      border: 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 30px 0 16px;
      border: none;
      outline: none;
    }
  }
}
