.textInput {
  .password {
    display: flex;
    justify-content: space-between;
  }

  .label {
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    font-size: 0.875em;
    line-height: 128.571%;
    margin-bottom: 8px;
    display: block;
    &.failtext {
      color: var(--light-sub-coral, #ff3737);
    }
  }
  .inputBox {
    border-radius: 4px;
    border: 1px solid var(--black-01);
    position: relative;
    z-index: 10;
    height: 42px;
    background: var(--black-005, rgba(0, 0, 0, 0.05));
    transition: all 0.2s;
    .input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    input {
      padding: 12px 13px;
      background-color: rgba(0, 0, 0, 0);
      width: calc(100% - 28px);
      font-size: 0.75em;
      font-weight: 400;
      line-height: 133.333%;
      &:focus {
        outline: none;
      }
      &[type="password"] {
        font-family: Verdana;
      }
      &::placeholder,
      &::-moz-placeholder {
        font-size: 0.75em !important;
      }
    }
    svg {
      margin: 0 13px;
      width: 18px;
      height: 18px;
    }
    &::before {
      display: block;
      content: "";
      border: 2px solid rgba(0, 0, 0, 0);
      position: absolute;
      top: -1px;
      left: 0;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border-radius: 4px;
      z-index: 0;
      transition: all 0.2s;
    }
    &.focus::before {
      border-color: var(--primary-mobile, #66b6ff);
    }
  }

  .state {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    font-size: 0.75em;
    line-height: 133.333%;
    color: var(--black-08, rgba(0, 0, 0, 0.8));
  }
  .success {
    color: var(--light-sub-green, #00c88c);
    svg {
      fill: var(--light-sub-green, #00c88c);
      width: 16px;
      height: 16px;
    }
  }
  .fail {
    color: var(--light-sub-coral, #ff3737);
    svg {
      fill: var(--light-sub-coral, #ff3737);
      width: 16px;
      height: 16px;
    }
  }
}
