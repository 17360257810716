.reportCard {
  padding: 1em;
  margin-bottom: 1em;
  background: var(--white);
  border-radius: 1em;
  .topArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date {
      font-size: 0.85em;
      font-weight: 300;
      line-height: 123.077%;
      span {
        font-weight: 400;
        margin-left: 0.6em;
      }
    }
    .moreBtn {
      display: flex;
      align-items: center;
      font-size: 0.875em;

      div {
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
          margin-left: 0.3em;
        }
      }

      div.delete {
        height: 20px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .companyname {
    color: var(--primary-mobile);
    font-size: 1.25em;
    font-weight: 700;
    line-height: 120%;
    margin: 0.5rem 0 1rem;
  }
  .resultArea {
    .resultTite {
      color: var(--black-06);
      font-size: 0.75em;
      line-height: 133.333%;
      font-weight: 400;
      margin-bottom: 0.25em;
    }
    .resultList {
      .li {
        color: var(--black-08);
        font-size: 1em;
        font-weight: 400;
        line-height: 125%;
        padding: 0.625em 0.75em;
        border-radius: 0.5em;
        background: var(--black-005, rgba(0, 0, 0, 0.05));
        margin-bottom: 0.25em;
        display: flex;
        align-content: center;
        gap: 12px;
        min-height: 20px;
        .icon {
          width: 20px;
          height: 20px;
        }
        .line {
          width: 1px;
          height: 16px;
          background: var(--black-02);
          display: block;
          margin: 2px 0;
        }
      }
    }
  }
}
