.nodata {
  min-height: calc(70vh);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    padding: 0 0.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    .icon {
      width: 42px;
      height: 42px;
      svg {
        width: 100%;
        height: 100%;
        fill: var(--black-06);
      }
    }

    .title {
      font-size: 1.5em;
      line-height: 125%;

      color: var(--black-06);
    }
    .text {
      font-size: 1em;
      line-height: 125%;

      color: var(--black-06);
    }
    .desc {
      font-size: 0.75em;
      line-height: 133.333%;
      color: var(--black-06);
      word-break:keep-all;
    }
  }
}
