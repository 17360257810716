.secessionModal {
  .item {
    padding-top: 16px;
    border-top: 1px solid var(--black-01);
    margin-top: 16px;
    &:first-child {
      border: none;
      margin-top: 0;
      padding-top: 0;
    }
  }
  .desc {
    margin-top: 16px;
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    font-size: 0.75em;
    font-weight: 400;
    line-height: 133.333%;
    span{
        color: var(--light-sub-coral, #FF3737);
    }
  }
}
