.step2 {
  padding: 0.25em 1.5em 6em;
  min-height:calc(100vh - 100px - 0.75em - 6.25em);
  background: var(--White-2, #F8F8F8);
  section {
    border-top: 1px solid var(--black-01);
    padding: 1.25em 0;
    &:first-child{
        border:none;
    }
    .label {
      font-size: 0.875em;
      font-style: normal;
      font-weight: 500;
      span {
        color: var(--primary-mobile, #66b6ff);
      }
    }
    .info {
      border-radius: 4px;
      border: 1px solid var(--black-01, rgba(0, 0, 0, 0.1));
      background: var(--black-005, rgba(0, 0, 0, 0.05));
      padding: 0.75em;
      margin: 0.75em 0;
      height: 160px;
      overflow-y: auto;
    }
  }
  .section1 {
    .desc {
      color: var(--black, #000);
      font-size: 0.813em;
      font-weight: 300;
      line-height: 123.077%;
      margin-top: 0.75em;
    }
  }
}
