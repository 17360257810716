@import "../../inputs/textInput/textInput.module.scss";

.deleteReportModal {
  .itemTitle {
    color: var(--Black, #000);
    font-size: 1em;
    line-height: 128.571%;
    margin-bottom: 8px;
    text-align: center;
  }

  .itemContents {
    font-size: 0.825rem;
    color: var(--black-08, rgba(0, 0, 0, 0.8));
  }
}
