.step1 {
  background: var(--White-2, #F8F8F8);
  min-height:calc(100vh - 260px); //100px - 0.75em)
  .icon {
    text-align: center;
  }
  .btn {
    text-align: center;
    margin-bottom:12px;
    button {
      width: 240px;
      font-size:1em;
      padding: 0.8em 0.8em; 
      border-radius: 0.4em;
    }
  }
  .info {
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    text-align: center;
    font-size: 1em;
    font-style: normal;
    font-weight: 300;
    line-height: 125%;
  }
  .step1_1 {
    .icon {
      padding: 4rem 0 3rem;
    }
    .btn {
      
    }
  }
  .step1_2 {
    padding:0 2em;
    .icon {
      padding: 4rem 0 1.5rem;
    }
    .info {
        margin-bottom:3em;
    }
  }
}
