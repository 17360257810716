@keyframes open {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.recommendedEX {
  .exCate {
    .cateIcon {
    }
    .cateText {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.13em 0 0.8em;
      border-bottom: 1px solid var(--black-01);
      .date {
        color: var(--black-08);
        font-size: 0.85em;
        font-weight: 300;
        line-height: 123.077%;
        display: flex;
        align-items: center;
        span {
          font-weight: 400;
          display: inline-block;
          margin-left: 0.6em;
        }
        svg {
          width: 16px;
          height: 16px;
          fill: var(--black);
          margin-left: 12px;
          display: inline-block;
        }
      }
      .title {
        font-size: 1em;
        font-weight: 500;
        line-height: 125%;
      }
    }
  }
  .exList {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    padding: 0.8em 0;
    // transition: all 0.3s;
  }
}

.recommendedEXCard {
  display: flex;
  align-items: center;
  border-radius: 0.4em;
  .icon {
    width: 90px;
    height: 80px;
    min-width: 90px;
    border-radius: 4px;
    background-color: var(--black-01);
    margin-right: 16px;
    overflow: hidden;
  }
  .infoArea {
    margin-right: 32px;
    flex: 1;
    word-break: break-word;
    h4 {
      color: var(--black, #000);
      font-size: 1em;
      font-weight: 500;
      line-height: 125%;
    }
    p {
      color: var(--black-08, rgba(0, 0, 0, 0.8));
      font-size: 0.85em;
      font-weight: 300;
      line-height: 123.077%;
      margin-top:8px;
      b{
        margin-left:6px;
      }
    }
  }
  .playBtn {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
  }
}
