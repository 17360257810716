.tabSlier{
    .tabBar{
        display:flex;
        align-items: center;
        justify-content: center;
        gap:24px;
        margin-bottom:16px;
        .tab{
            width:3em;
            text-align: center;
            color: var(--black-06, rgba(0, 0, 0, 0.60));
            font-size: 1em;
            font-weight: 500;
            line-height: 125%;
            .bar{
                width:100%;
                display:block;
                height: 2px;
                align-self: stretch;
                background: var(--black);
                margin-top:4px;
                opacity: 0;
                // transition: all 0.3s;
            }
            &.on{
                color: var(--black, rgba(0, 0, 0));
                .bar{
                    opacity: 1;
                }
            }
        }
    }
    .imgSlider{
        width:100vw;
        max-width:430px;
        overflow: hidden;
        .slider{
            width:300%;
            display:flex;
            align-items: center;
            gap: 2%;
            margin-left:11.5%;
            transition: all 0.3s;
            .img{
                width:calc(33% - 7.5%);
                background-color:var(--mode-opacity-20);
                height:450px;
                border-radius: 1em;
                overflow: hidden;
                border:1px solid var(--black-005);
                position:relative;
                img{
                    width:100%;
                    height:100%;
                    object-fit: cover;
                    position: absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}