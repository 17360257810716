.moreBtn{
    display:flex;
    align-items: center;
    gap:8px;
    justify-content: flex-end;
    i{
        width: 16px;
        height: 16px;
        display:flex;
        align-items: center;
        svg{
            width:100%;
            height:100%;
            fill:var(--black);
        }
    }
    .text{
        font-size: 14px;
    }
}