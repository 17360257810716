.sectionTitle{
    display:flex;
    color: var(--black-08, rgba(0, 0, 0, 0.80));
    line-height: 125%;
    align-items: center;
    gap:0.5rem;
    font-size:1.125em;
    font-weight: 400;
    .icon{
        width: 1.5em;
        height: 1.5em;
        display:flex;
        align-items: center;
        justify-content: center;
        svg{
            width : 100%;
        }
    }
   
}