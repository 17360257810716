.dormant{
    padding:120px 1.5em 230px;
    background: var(--white-2, #F8F8F8);
    min-height:calc(100vh - 350px);
    .title{
        color: var(--primary-mobile, #66B6FF);
        font-size: 1.875em;
        font-weight: 700;
        line-height:120%;
    }
    .desc{
        color: var(--black, #000);
        font-size: 1em;
        font-weight: 400;
        line-height:125%;
        margin:2em 0 1em;
    }
    .desc2{
        color: var(--black-06, rgba(0, 0, 0, 0.60));
        font-size: 0.813em;
        font-style: normal;
        font-weight: 300;
        line-height:123.077%;
        padding-bottom:1em;
       
    }
    section{
        border-top:1px solid var(--black-02);
        border-bottom:1px solid var(--black-02);
        display:flex;
        flex-direction: column;
        gap:0.75em;
        padding:1.5em 0;
        p{
            display:grid;
            grid-template-columns: 90px 1fr;
            color: var(--black-08, rgba(0, 0, 0, 0.80));
            font-size: 0.875em;
            font-style: normal;
            font-weight: 300;
            line-height:128.571%;
            b{
                font-size:1.143em;
            }
        }
    }
    .btns{
        margin-top:2em;
        display:flex;
        flex-direction: column;
        gap:0.75em;
        button{
            font-size: 1em;
            &:last-child{
                background-color:rgba(0,0,0,0);
                border: 1px solid var(--primary-mobile, #66B6FF);
                color : var(--primary-mobile, #66B6FF);
            }
        }
    }
}