.upBtn {
  position: sticky;
  bottom: 6.25em;
  right:1.5em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-mobile);
  display:flex;
  align-items: center;
  justify-content: center;
  margin-left:auto;
  svg{
    width: 20px;
    height: 20px;
    fill:var(--white);
  }
}
