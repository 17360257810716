.exercise {
  min-height:90vh;
  background: var(--white-2);
  padding: 0 1.5rem 6rem 1.5rem;
  width: calc(100% - 3rem);
  max-width: calc(430px - 3rem);
  section{
    margin-top:1.5rem;
}
}

.view {
  width: 100vw;
  max-width: 430px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background-color: #fff;
  display: flex;
  align-items: center;
  div {
    overflow-y: auto;
    width: 100%;
    video {
      width: 100%;
      height:auto;
      margin-top:16px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
    }
  }
}
