@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.joinInput {
  height: 53px;
  width: 100%;
  animation: visible 0.5s;
}

.joinBox {
  position: relative;
  height: 52px;
  width: 100%;
}

.label {
  font-weight: 300;
  font-size: 1em;
  line-height: 20px;
  color: var(--black-06, rgba(0, 0, 0, 0.6));
  position: absolute;
  top: 18px;
  left: 0;
  transition: all 0.3s;
}
.inputArea {
  width: 100%;
  position: absolute;
  top: 21px;
  left: 0;
  padding-bottom: 12px;
  display: flex;
  gap: 4px;
  border-bottom: 1px solid var(--black-02, rgba(0, 0, 0, 0.2));
  svg {
    fill: #fff;
  }
  .input {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--white_08);
    flex: 1;
    display: block;
    width: calc(100% - 22px);
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    &[type="password"] {
      font-family: Verdana;
      font-size:16px;
    }
    &::placeholder, &::-moz-placeholder{
      font-size: 0.75em !important;
    }
  }
}
.underText {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: var(--white_08);
  margin-top: 4px;
}

.fail {
  .inputArea {
    border-color: var(--mode-sub-coral);
  }

}

.isValue {
    .label {
        top: 0px;
        font-size: 0.7em;
      }
    .inputArea {
        border-color: var(--black-02, rgba(0, 0, 0, 0.2));
      }
}


.isfocus {
  .label {
    top: 0px;
    font-size: 0.7em;
  }
  .inputArea {
    border-color: var(--black, #000);
  }
}
