.changeInputModal {
  .itemTitle {
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    font-size: 0.875em;
    line-height: 128.571%;
    margin-bottom: 8px;
    &.failtext {
      color: var(--light-sub-coral, #ff3737);
    }
  }
  .value {
    color: var(--black, #000);
    font-size: 1em;
    font-weight: 500;
    line-height: 125%;
    padding: 8px 0 16px;
    border-bottom: 1px solid var(--black-01);
    margin-bottom: 16px;
  }
  .checkBtn {
    margin-top: 8px;
    button {
      width: 100%;
      background: var(--black-04, rgba(0, 0, 0, 0.4));
      border: none;
    }
  }
}
