.prevBtn {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid var(--primary-mobile);
  color: var(--primary-mobile);
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  line-height: 125%;
}
