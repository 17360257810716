

.step3 {
  section {
    // margin-bottom: 0.75em;
    background: var(--white-2, #f8f8f8);
    padding: 1.5rem 1.5rem 96px 1.5rem;
    input {
      font-size: 0.9em;
    }
  }
  .section1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    label{
      color:var(--black);
    }
    & > .item {
      border-top: 1px solid var(--black-01);
      color: var(--black-06);
      font-size: 14px;
      font-weight: 300;
      line-height: 128.571%;
      display: grid;
      grid-template-columns: 100px 1fr 46px;
      gap: 12px;
      &:first-child {
        border: none;
      }
      b {
        font-size: 16px;
      }
    }
    .selectTitle {
      font-size: 14px;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.3px;
      margin-bottom:0.5rem;
    }
  }
  //.section2 {
  .emailArea {
    width: 100%;
  }
  .itemTitle {
    margin-bottom: 8px;
  }
  .select {
    width: 138px;
    height: 42px;
    margin-top: -2px;
    & > div {
      width: 135px;
      background: var(--black-005, rgba(0, 0, 0, 0.05));
      border: 1px solid var(--black-01, rgba(0, 0, 0, 0.1));
      border-radius: 4px;
    }
  }
  select {
    width: 100%;
    height: 100%;
    &:focus {
      outline-color: var(--primary-mobile, #66b6ff);
    }
  }
  .selectGender {
    width: calc(100% - 2px);
    height:66px;
    .select, .select > div {
      width: 100%;
    }
  }

  .selectEmail {
    display: flex;
    gap: 8px;
    margin: 8px 0;
    width: 100%;
    align-items: center;
    .emailIcon {
      display: flex;
      align-items: center;
      font-size: 0.9em;
    }

    .emailInput {
      flex: 1;
      max-width: calc(100% - 159px);
      div {
        width: 100%;
      }
    }
  }
  .emailText {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    font-size: 0.75em;
    line-height: 133.333%;
    color: var(--black-08, rgba(0, 0, 0, 0.8));
    &.success {
      color: var(--light-sub-green, #00c88c);
      svg {
        fill: var(--light-sub-green, #00c88c);
        width: 16px;
        height: 16px;
      }
    }
    &.fail {
      color: var(--light-sub-coral, #ff3737);
      svg {
        fill: var(--light-sub-coral, #ff3737);
      }
    }
  }
  // }
  .emailArea {
    // padding-bottom: 1em;
    button {
      width: 100%;
      margin-top: 1em;
    }
  }
  .pwArea {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1px solid var(--black-01);
    border-bottom: 1px solid var(--black-01);
    padding: 1em 0;
  }
  .nicknameArea {
    button {
      width: 100%;
      margin-top: 1em;
    }
  }
  .section3 {
    padding-bottom: 6em;
    .label {
      color: var(--black, #000);
      font-size: 1em;
      font-weight: 500;
      line-height: 125%;
    }
    .desc {
      color: var(--black-06, rgba(0, 0, 0, 0.6));
      font-size: 0.813em;
      font-weight: 400;
      line-height: 123.077%;
      margin: 0.75em 0;
    }
    .checkLabel {
      color: var(--black, #000);
      font-size: 0.875em;
      font-weight: 500;
      line-height: 128.571%;
    }
  }
  .line {
    background-color: var(--black-01);
    height: 1px;
  }
}
