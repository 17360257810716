.deviationCard{
    display:flex;
    gap:8px;
    padding:12px 0 10px;
    border-bottom:1px dashed  var(--black-02);
    .icon{
        width: 32px;
        height: 72px;
    }
    .descArea{
        flex:1;
        .title{
            font-size: 1em;
            font-weight: 500;
            line-height:  125%;
            display:flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom:5px;
            .text{
                width: 60%;
                min-height:20px;
                display: inline-block;
            }
            .deviation{
                display:flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                min-height:27px;
                padding:0 12px;
                color: var(--white, #FFF);
                font-family:"Pretendard";
                font-size: 0.9em;
                font-weight: 700;
                letter-spacing: -0.5px;
                border-radius: 16px;
                
            }
        }
        .text{
            font-size: 0.8em;
            font-weight: 400;
            line-height: 133.333%;
            min-height:32px;
            span{
                color: var(--black-06, rgba(0, 0, 0, 0.60));
                word-break :keep-all;  
            }
        }
    }
    .deviation{
        font-weight: bold;
    }
    &:last-child{
        border:none;
    }
    &:nth-child(1){
        .title{
           color: var(--primary-mobile, #66B6FF);
            .deviation{
                background: var(--primary-mobile, #66B6FF);
            }

        }
    }
    &:nth-child(2){
        .title{
           color: var(--light-sub-green, #00C88C);
            .deviation{
                background:  var(--light-sub-green, #00C88C);
            }

        }
    }
    &:nth-child(3){
        .title{
           color: var(--light-sub-orange, #FF8D23);
            .deviation{
                background: var(--light-sub-orange, #FF8D23);
            }

        }
    }
    &:nth-child(4){
        .title{
           color: var(--light-sub-cobalt-blue, #264A9F);
            .deviation{
                background: var(--light-sub-cobalt-blue, #264A9F);
            }

        }
    }
}